<template>
  <div class="export-simulation">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop="handleExportSimulation"
        >
          <font-awesome-icon icon="cloud-download-alt" />
        </div>
      </template>
      <span>Télecharger simulations</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    filter: { required: true }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['exportSimulation']),
    async handleExportSimulation() {
      const response = await this.exportSimulation(this.filter);
      if (response.succes) {
        this.$alert('', response.msg, 'success');
      }
    }
  },
  computed: {
    ...mapGetters(['getLoaderExportSimulation'])
  }
};
</script>
